
import {defineComponent, onMounted} from "vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import EmployeeOverviewTable from "@/components/widgets/tables/Employees.vue";
import {Api} from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "EmployeeOverview",
  components: {
    EmployeeOverviewTable,
  },
 
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Přehled zaměstnanců", ["Zaměstnanci"]);
    });
    return {
      dataApi: Api.ALL_EMPLOYEES
    }
  }
});
